import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { Theme } from 'src/app/app.model';
import {
  Answer,
  HistoryItem,
} from 'src/app/features/feature-branch-intelligent-search/search-results/search.model';
import { LoaderService } from 'src/app/shared/ui/loader/loader.service';
import { environment } from 'src/environments/environment.prd';
import { Client } from '../models/client.model';
import { Region } from '../models/region.model';
import { ScreenSize } from '../models/screen.model';
import {
  SubscriptionModel,
  SubscriptionStatus,
  SubscriptionToolCode,
} from '../models/subscription.model';
import { Status, User } from '../models/user.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  localStorageService = inject(LocalStorageService);
  apiUrl = environment.apiUrl;

  isMenuOpen$ = new BehaviorSubject<boolean>(false);
  isSmallScreen$ = new BehaviorSubject<boolean>(window.innerWidth <= 768);
  screenSize$ = new BehaviorSubject<ScreenSize>(ScreenSize.XS);
  isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isPageRefreshed$ = new BehaviorSubject<boolean>(false);
  tokenRefreshed$ = new BehaviorSubject<string>('');
  user$ = new BehaviorSubject<User>({} as User);
  userValue$ = this.user$.asObservable();
  userStatus$ = new BehaviorSubject<Status>(Status.INIT);
  searchInProgress$ = new BehaviorSubject<boolean>(false);
  private historySubject = new BehaviorSubject<HistoryItem[]>([]);
  searchQuestion$ = new BehaviorSubject<string>('');
  currentUrl$ = new BehaviorSubject<string>('');
  isErrorPage$ = new BehaviorSubject<boolean>(false);
  coutryChange$ = new Subject<void>();

  private themeSelectedSubject = new BehaviorSubject<string>(
    localStorage.getItem('theme') || 'light-theme'
  );
  themeSelected$ = this.themeSelectedSubject.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchSubject = new BehaviorSubject<any>(null);
  search$ = this.searchSubject.asObservable();

  clientSubject = new BehaviorSubject<Client>(Client.ENSTALL_EU);
  client$ = this.clientSubject.asObservable();

  private domainNameSubject = new BehaviorSubject<string>('');
  domainName$ = this.domainNameSubject.asObservable();

  private isRedirectionSubject = new BehaviorSubject<boolean>(false);
  isRedirection$ = this.isRedirectionSubject.asObservable();

  private readonly US_COUNTRIES = ['US', 'CA', 'MX'];

  constructor(public loader: LoaderService) {
    window.addEventListener('resize', () => {
      this.isSmallScreen$.next(window.innerWidth <= 768);
      this.setScreenSize();
    });
  }

  setDomainName(domainName: string): void {
    this.domainNameSubject.next(domainName);
    this.localStorageService.setItem('domainName', domainName);
  }

  setClient(client: Client) {
    this.clientSubject.next(client);
    this.localStorageService.setItem('client', client);
  }

  setClientAndTheme(client: Client): void {
    this.setClient(client);
    let theme: Theme;
    switch (client) {
      case Client.IRONRIDGE:
        theme = Theme.IRONRIDGE;
        break;
      case Client.ENSTALL:
        theme = Theme.ENSTALL;
        break;
      case Client.ENSTALL_EU:
        theme = Theme.ENSTALL_EU;
        break;
      case Client.ESDEC:
        theme = Theme.ESDEC;
        break;
      case Client.ECOFASTENSOLAR:
        theme = Theme.ECOFASTENSOLAR;
        break;
      default:
        theme = Theme.ESDEC;
        break;
    }
    this.themeSelectedSubject.next(theme);
  }

  setTheme(theme: Theme): void {
    this.themeSelectedSubject.next(theme);
    this.localStorageService.setItem('theme', theme);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSearchTerm(searchTerm: Answer | any): void {
    this.searchSubject.next(searchTerm);
  }

  setRedirection(isRedirection: boolean): void {
    this.isRedirectionSubject.next(isRedirection);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateHistory(historyItem: any) {
    const history = this.historySubject.value;
    history.push(historyItem);
    this.historySubject.next(history);
  }

  setScreenSize() {
    const screenWidth = window.innerWidth;
    switch (true) {
      case screenWidth < 480:
        this.screenSize$.next(ScreenSize.XS);
        break;
      case screenWidth >= 480 && screenWidth < 768:
        this.screenSize$.next(ScreenSize.S);
        break;
      case screenWidth >= 768 && screenWidth <= 1023:
        this.screenSize$.next(ScreenSize.M);
        break;
      case screenWidth >= 1024 && screenWidth <= 1279:
        this.screenSize$.next(ScreenSize.L);
        break;
      case screenWidth >= 1280:
        this.screenSize$.next(ScreenSize.XL);
        break;
      default:
        this.screenSize$.next(ScreenSize.XS);
        break;
    }
  }

  /*
   * @deprecated This method causes false information since it is happening that user is not loaded yet, but this method is used.
   * Use **getRegionForUser** instead.
   */
  getRegionForCurrentUser(): Region {
    const currentUser = this.user$.value;
    return this.getRegionForUser(currentUser);
  }

  isUserFromAnyCountry(countryCodes: Array<string>): Observable<boolean> {
    return this.user$.pipe(
      map(user =>
        countryCodes.some(countryCode => user.country === countryCode)
      )
    );
  }

  isUserFromRegion(region: Region): Observable<boolean> {
    return this.user$.pipe(map(user => this.getRegionForUser(user) === region));
  }

  isSubscriptionInStatus(
    toolCode: SubscriptionToolCode,
    status: SubscriptionStatus = SubscriptionStatus.ACTIVE
  ): Observable<boolean> {
    return this.user$.pipe(
      map(user => user.subscriptions),
      map((subscriptions: SubscriptionModel[]) =>
        this.checkStatus(subscriptions, toolCode, status)
      )
    );
  }

  checkStatus(
    subscriptions: SubscriptionModel[],
    toolCode: SubscriptionToolCode,
    status: SubscriptionStatus
  ): boolean {
    return (
      subscriptions?.some(
        subscription =>
          subscription.toolCode === toolCode && subscription.status === status
      ) ?? false
    );
  }

  getRegionForUser(user: User): Region {
    const userCountry = user.country ?? 'NL';
    return this.US_COUNTRIES.includes(userCountry) ? Region.USA : Region.EUROPE;
  }
}
