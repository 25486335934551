import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prd';
import { TokenType } from '../models/auth.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authService = inject(AuthService);

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    if (this.checkSkipUrl(request.url)) {
      return next.handle(request);
    }

    const tokenType = request.url.includes('/search')
      ? TokenType.ACCESS_TOKEN
      : TokenType.ID_TOKEN;

    return this.authService.acquireToken(tokenType).pipe(
      map(token => this.addTokenToRequest(request, token)),
      switchMap(modifiedRequest => next.handle(modifiedRequest))
    );
  }

  checkSkipUrl(url: string): boolean {
    const skipUrls = [
      '/assets/i18n/en-US.json',
      '/assets/i18n/de-DE.json',
      '/assets/i18n/es-ES.json',
      '/assets/i18n/fr-FR.json',
      '/assets/i18n/nl-NL.json',
      '/assets/i18n/pl-PL.json',
      '/assets/i18n/pt-PT.json',
      '/assets/i18n/sv-SE.json',
      `${environment.apiUrl}/verification/`,
    ];

    return skipUrls.some((skipUrl: string) => url.indexOf(skipUrl) > -1);
  }

  private addTokenToRequest(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    token: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
