import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { concatMap, filter, map } from 'rxjs';
import { Status, User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { GaService } from '../services/ga.service';
import { StateService } from '../services/state.service';
import { UserService } from '../services/user.service';
import { getUrlPath } from '../utils/route.util';

export const navigationGuard: CanActivateFn = route => {
  const gaService = inject(GaService);
  sendGaEvent(gaService);
  const stateService = inject(StateService);
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    concatMap(() =>
      userService
        .getActiveUser()
        .pipe(map(user => check(user, route, stateService, authService)))
    )
  );
};

function check(
  user: User,
  route: ActivatedRouteSnapshot,
  stateService: StateService,
  authService: AuthService
): boolean {
  const currentUrl = getUrlPath(route);
  const status = authService.getUserStatusFromUser(user);
  stateService.currentUrl$.next(currentUrl);

  stateService.isErrorPage$.next(currentUrl.includes('error-page'));

  if (!status || status === Status.INIT) {
    return false;
  }

  const canAccess = !!route.data.statuses.includes(status);

  if (!canAccess) {
    authService.navigateUser(user, currentUrl);
    return false;
  }
  return canAccess;
}

function sendGaEvent(gaService: GaService) {
  const data = {
    event: 'page_guard',
  };
  gaService.sendGaData(data);
}
