import { PromptValue } from '@azure/msal-browser';
import { CommonEnvironmentModel } from './environment.model';

export const environmentCommon: CommonEnvironmentModel = {
  prompt: PromptValue.SELECT_ACCOUNT,
  enstallSubdomain: 'my',
  ironridgeSubdomain: 'ironridge',
  esdecSubdomain: 'esdec',
  ecofastensolarSubdomain: 'ecofastensolar',
  defaultLanguage: 'en-US',
  emailRegexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
};
