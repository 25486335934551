import { BrowserCacheLocation } from '@azure/msal-browser';
import { Client } from '../app/core/models/client.model';
import { environmentCommon } from './environment.common';
import {
  CoreEnvironmentModel,
  EnvironmentModel,
  LegislationLinksEnvironmentModel,
  MsalConfigEnvironmentModel,
  ToolLinksEnvironmentModel,
} from './environment.model';
import { EnvironmentName } from './util/environment.name';

const environmentCore: CoreEnvironmentModel = {
  name: EnvironmentName.dev,
  production: false,
  apiUrl: 'https://be.my.dev.enstall.com/api',
  searchApiUrl: 'https://gipis.my.dev.enstall.com/api/v1/search',
};

const msalConfig: MsalConfigEnvironmentModel = {
  auth: {
    baseAuthorityUrl:
      'https://enstallgipdev.b2clogin.com/enstallgipdev.onmicrosoft.com/',
    clientId: '51e5c498-3bf0-4f18-ad00-17abd16c8215',
    authority: 'B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
    signUpAuthority: 'B2C_1A_NEURON_SIGNUP',
    passwordResetAuthority: 'B2C_1A_SEAMLESSMIGRATION_PASSWORDRESET',
    knownAuthorities: ['enstallgipdev.b2clogin.com'],
    scopes: [
      'https://enstallgipdev.onmicrosoft.com/21031f7c-eff4-427c-bba9-52b1a7ee870c/Data.Read',
    ],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
};

const toolLinks: ToolLinksEnvironmentModel = {
  ironridgeProjectsLink: 'https://dev-ridge2.com/projects',
  projectsUrl: 'https://dev-ridge2.com',
  trainingUrl:
    'https://ironridgedev.wpengine.com/resources/on-demand-training/',
  installCertificationUrl:
    'https://ironridgedev.wpengine.com/resources/installer-cert/',
  calculatorUrl: 'https://esdec-test.quintor.nl/login',
  academyUrl: 'https://esdeclearning.sandbox.myabsorb.eu/',
  onsitePageUrl: 'https://www.esdec.com/{language}/my-esdec/#esdec_onsite',
  onsiteAppUrl: 'https://esdec.web.ensapp.accept.paqt.io',
  chapterUrl: 'https://my.dev.enstall.com/enstall-services/chapter',
  erpUrl: 'https://my.dev.enstall.com/enstall-services/one-flow',
  designAssistantUrl: 'https://usdev1.efda.solar/dashboard',
  ecofastenAcademyUrl: 'https://ecofastensolar.sandbox.myabsorb.com',
  ecofastenInsightUrl: '',
  enstallErpUrl:
    'https://opusflow-git-enstall-sso.preview.opusflow.io/sign-in?provider=enstall',
  ironridgeCompassUrl: '',
};

const legislationLinks: LegislationLinksEnvironmentModel = {
  cookiesPolicy: {
    [Client.ENSTALL]: 'https://www.enstall.com/cookies-policy/',
    [Client.ENSTALL_EU]: 'https://www.enstall.com/cookies-policy/',
    [Client.IRONRIDGE]: 'https://www.ironridge.com/cookies-policy/',
    [Client.ESDEC]: 'https://www.esdec.com/en/cookies/',
    [Client.ECOFASTENSOLAR]: 'https://www.ecofastensolar.com/cookies-policy/',
    ['Panelclaw']: 'https://panelclaw.eu/en/cookie-statement/',
  },
  privacyPolicy: 'https://enstall.com/privacy-policy',
  termsOfUse:
    'https://enstall.com/wp-content/uploads/2024/03/Enstall-Digital-Services-Platform-Terms-of-Use.pdf',
};

export const environment: EnvironmentModel = {
  ...environmentCommon,
  ...environmentCore,
  msalConfig,
  ...toolLinks,
  ...legislationLinks,
};
