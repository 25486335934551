export interface Error {
  status: number;
  message: string;
}

export enum ErrorType {
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
}
