import { environment } from 'src/environments/environment.prd';
import { EnvironmentName } from 'src/environments/util/environment.name';

export const isAnyEnvironmentOf = (
  environmentNames: Array<EnvironmentName>
): boolean => {
  if (environmentNames?.length < 1) {
    throw new Error('Parameter list is either empty or null/undefined.');
  }
  return environmentNames.some(
    environmentName => environmentName === environment.name
  );
};
