import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { concatMap, filter, map, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GaService } from '../services/ga.service';

export const googleTagManagerUserGuard: CanActivateFn = () => {
  const gaService = inject(GaService);
  const authService = inject(AuthService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    concatMap(() =>
      authService.getUserUuid().pipe(
        tap(epUserUuid => sendGaEvent(epUserUuid!, gaService)),
        map(() => true)
      )
    )
  );
};

function sendGaEvent(user_uuid: string, gaService: GaService) {
  const data = {
    event: 'message',
    user_uuid,
  };
  gaService.sendGaData(data);
}
