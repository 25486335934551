<ng-container *ngIf="stateService.themeSelected$ | async as themeSelected">
  <div class="spinner-wrapper" *ngIf="loaderService.isLoading$ | async">
    <div
      class="spinner-border"
      [ngClass]="{
        'text-white': themeSelected === Theme.ENSTALL
      }"
      style="z-index: 999"
      role="status"></div>
  </div>
</ng-container>
