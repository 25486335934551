import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationSkipped,
  Router,
  RouterOutlet,
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment.prd';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { BehaviorSubject, debounceTime, filter, map, tap } from 'rxjs';
import { Theme } from './app.model';
import { AppService } from './app.service';
import { IdTokenClaimsWithPolicyId } from './core/models/auth.model';
import { Status } from './core/models/user.model';
import { AuthService } from './core/services/auth.service';
import { GaService } from './core/services/ga.service';
import { StateService } from './core/services/state.service';
import { ConfirmationModalComponent } from './shared/ui/confirmation-modal/confirmation-modal.component';
import { EnvironmentNotificationComponent } from './shared/ui/environment-notification/environment-notification.component';
import { HeaderComponent } from './shared/ui/header/header.component';
import { InputFieldComponent } from './shared/ui/input-field/input-field.component';
import { LoaderComponent } from './shared/ui/loader/loader.component';
import { LoaderService } from './shared/ui/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterOutlet,
    TranslateModule,
    InputFieldComponent,
    HeaderComponent,
    EnvironmentNotificationComponent,
    LoaderComponent,
    ConfirmationModalComponent,
  ],
})
export class AppComponent implements OnInit {
  authService = inject(AuthService);
  router = inject(Router);
  location = inject(Location);
  gaService = inject(GaService);
  isIframe = false;
  loginDisplay = false;
  Status = Status;
  Theme = Theme;
  environment = environment;

  private blockGAOnLoginSuccessEventTriggered$ = new BehaviorSubject<boolean>(
    false
  );

  // handles when a user logs in or out of another tab or window
  externalLogInOut$ = this.msalBroadcastService.msalSubject$.pipe(
    filter(
      (msg: EventMessage) =>
        msg.eventType === EventType.ACCOUNT_ADDED ||
        msg.eventType === EventType.ACCOUNT_REMOVED
    ),
    tap(() => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        window.location.pathname = '/';
      } else {
        this.setLoginDisplay();
      }
    })
  );

  checkAndSetActiveAccount$ = this.msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    tap(() => this.setLoginDisplay()),
    tap(() => this.appService.checkAndSetActiveAccount())
  );

  loginSuccess$ = this.msalBroadcastService.msalSubject$.pipe(
    filter(
      (msg: EventMessage) =>
        msg.eventType === EventType.LOGIN_SUCCESS ||
        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        msg.eventType === EventType.SSO_SILENT_SUCCESS
    ),
    map((result: EventMessage) => ({
      payload: result.payload as AuthenticationResult,
      idTokenClaims: (result?.payload as AuthenticationResult)
        ?.idTokenClaims as IdTokenClaimsWithPolicyId,
    })),
    tap(({ payload, idTokenClaims }) => {
      this.appService.setup(payload, idTokenClaims);
    })
  );

  sendGADataOnLoginSuccess$ = this.msalBroadcastService.msalSubject$.pipe(
    filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),

    map((result: EventMessage) => result.payload as AuthenticationResult),
    debounceTime(2000), // this event needs to be triggered at the end of loading page and after `this.router.events`
    tap((payload: AuthenticationResult) => {
      if (!this.blockGAOnLoginSuccessEventTriggered$.value) {
        this.appService.sendGaLoginData(payload.idToken);
      }
    })
  );

  isRedirection$ = this.stateService.isRedirection$;

  constructor(
    public loader: LoaderService,
    public stateService: StateService,
    public appService: AppService,
    private route: ActivatedRoute,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.router.events.subscribe(event => {
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationSkipped
      ) {
        this.sendGaEvent();
        this.appService.setTitle(this.route);
        this.stateService.isErrorPage$.next(!!event.url.includes('error-page'));
        this.blockGAOnLoginSuccessEventTriggered$.next(
          !!event.url.includes('verify-email')
        );
      }
    });
  }

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener;

    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalService.instance.enableAccountStorageEvents();

    this.stateService.setScreenSize();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.route.fragment.subscribe((fragmentString: any) => {
      this.setTokenFromFragmentString(fragmentString);
    });
    this.authService.checkDomainName();
  }

  private sendGaEvent() {
    const data = {
      event: 'page_loaded',
      user_uuid: this.stateService.user$.getValue().epUserUuid,
    };
    this.gaService.sendGaData(data);
  }

  private setTokenFromFragmentString(fragmentString: string) {
    if (fragmentString) {
      let token = new URLSearchParams(fragmentString).get('id_token');
      if (token) {
        token = token.replace(/"/g, '');
        this.setLoginDisplay();
        this.msalService.instance.enableAccountStorageEvents();
        this.appService.checkAndSetActiveAccount();
        const result = {
          idToken: token,
        } as AuthenticationResult;
        this.appService.setup(result);
      } else {
        throw 'No token found in fragment string';
      }
    }
  }

  private setLoginDisplay(): void {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }
}
