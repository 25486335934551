import { IdTokenClaims } from '@azure/msal-browser';

export type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

export enum TokenType {
  ACCESS_TOKEN = 'accessToken',
  ID_TOKEN = 'idToken',
}
