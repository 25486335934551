import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ButtonType,
  CustomButtonColor,
  CustomButtonPadding,
  CustomButtonSize,
} from './button-types.model';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input({ required: true }) name!: string;
  @Input({ required: true }) buttonText!: string;
  @Input({ required: true }) bgColorClass!: CustomButtonColor;
  @Input({ required: true }) paddingClass!: CustomButtonPadding;
  @Input({ required: true }) sizeClass!: CustomButtonSize;
  @Input({ required: false }) type = ButtonType.BUTTON;
  @Input({ required: false }) iconBefore?: string;
  @Input({ required: false }) iconAfter?: string;
  @Input({ transform: booleanAttribute, required: false }) disabled = false;
  @Input({ required: false }) tabIndex: number | string = 0;
  @Output() buttonClicked = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClicked.emit();
  }
}
