import { Inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  IdTokenClaims,
  SsoSilentRequest,
} from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment.prd';

import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data } from '@angular/router';
import { IdTokenClaimsWithPolicyId } from './core/models/auth.model';
import { User } from './core/models/user.model';
import { AuthService } from './core/services/auth.service';
import { GaService } from './core/services/ga.service';
import { LanguageService } from './core/services/language.service';
import { StateService } from './core/services/state.service';
import { UserService } from './core/services/user.service';
import { LoaderService } from './shared/ui/loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  apiUrl = environment.apiUrl;
  host!: string;

  constructor(
    private msalService: MsalService,
    public loader: LoaderService,
    public stateService: StateService,
    private authService: AuthService,
    private userService: UserService,
    private gaService: GaService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private _document: Document,
    private titleService: Title
  ) {
    this.host = document.location.protocol + '//' + document.location.host;
  }

  setFavicon() {
    const faviconName = `assets/images/${this.authService.getDomainName()?.toLowerCase()}-favicon.png`;
    this._document.getElementById('favicon')?.setAttribute('href', faviconName);
  }

  setTitle(route: ActivatedRoute) {
    const rt = this.getChildRoute(route);

    rt.data.pipe(take(1)).subscribe((data: Data) => {
      if (data['title']) {
        const domainName = this.authService.getDomainName();
        const fullTitle = `${data.title} - ${domainName}`;
        this.titleService.setTitle(fullTitle);
      }
    });
  }

  setup(
    result: AuthenticationResult,
    idTokenClaims?: IdTokenClaimsWithPolicyId
  ) {
    /**
     * Below we are checking if the user is returning from the signUpSignIn flow.
     * If so, we have to set active account.
     */
    if (
      idTokenClaims?.acr === environment.msalConfig.auth.authority ||
      idTokenClaims?.tfp === environment.msalConfig.auth.authority
    ) {
      this.msalService.instance.setActiveAccount(result.account);
    }

    /**
     * Below we are checking if the user is returning from the reset password flow.
     * If so, we have to silently login user with signUpSignIn policy.
     */
    if (
      idTokenClaims?.acr ===
        environment.msalConfig.auth.passwordResetAuthority ||
      idTokenClaims?.tfp === environment.msalConfig.auth.passwordResetAuthority
    ) {
      this.loginAfterPasswordReset(idTokenClaims);
    }

    const idToken = result.idToken;
    if (idToken) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedToken: any = jwt_decode(idToken);
      this.setUser(decodedToken);
    }
  }

  private loginAfterPasswordReset(
    idTokenClaims: IdTokenClaimsWithPolicyId
  ): void {
    // retrieve the account from initial sing-in to the app
    const originalSignInAccount = this.msalService.instance
      .getAllAccounts()
      .find(
        (account: AccountInfo) =>
          account.idTokenClaims?.oid === idTokenClaims.oid &&
          account.idTokenClaims?.sub === idTokenClaims.sub &&
          ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
            environment.msalConfig.auth.authority ||
            (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
              environment.msalConfig.auth.authority)
      );
    const signUpSignInFlowRequest: SsoSilentRequest = {
      authority:
        environment.msalConfig.auth.baseAuthorityUrl +
        environment.msalConfig.auth.authority,
      account: originalSignInAccount,
    };

    // silently login again with the signUpSignIn policy
    this.msalService.ssoSilent(signUpSignInFlowRequest);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUser(decodedToken: any) {
    this.userService
      .getUserByUuid(decodedToken.epUserUuid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((user: any) => {
        this.authService.setUser(user);
        this.setFavicon();
        if (
          user != null &&
          user.preferredLanguage != null &&
          user.preferredLanguage != ''
        ) {
          this.languageService.setCurrentLang(user.preferredLanguage);
        }
      });
  }

  sendGaLoginData(token: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedToken: any = jwt_decode(token);
    const userId = decodedToken.epUserUuid;
    const data = {
      event: 'login',
      user_uuid: userId,
      origin: this.authService.getDomainName(),
      language: this.languageService.getCurrentLanguageCode(),
    };
    this.gaService.sendGaData(data);
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    } else {
      // setup application
      const userClaims = activeAccount?.idTokenClaims as IdTokenClaims &
        Partial<User>;
      this.setUserFromTokenClaims(userClaims);
    }
  }

  private getChildRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChildRoute(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private setUserFromTokenClaims(
    userClaims: IdTokenClaims & Partial<User>
  ): void {
    if (userClaims) {
      this.setUser(userClaims);
    }
  }
}
