import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { concatMap, filter, map } from 'rxjs';
import { Region } from '../models/region.model';
import { ClientService } from '../services/client.service';
import { UserService } from '../services/user.service';

export const clientRegionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const regionKey = 'region';
  const userService = inject(UserService);
  const clientService = inject(ClientService);
  const router = inject(Router);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    concatMap(() =>
      userService.getActiveUser().pipe(
        map(user => {
          if (
            (route.data[regionKey] as Region) ===
            clientService.getRegionForClientAndUser(user)
          ) {
            return true;
          }

          router.navigate(['/']);
          return false;
        })
      )
    )
  );
};
