<ng-container *ngIf="initElements$ | async"></ng-container>
<ng-container *ngIf="configureOnCountryChange$ | async"></ng-container>
<header>
  <app-environment-notification></app-environment-notification>

  <div class="bg-white header">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-row align-items-start">
        <app-header-logo
          [client]="client"
          [isTermsPage]="isTermsPage$ | async"
          (goToHome)="goHome()"></app-header-logo>
      </div>

      <div
        *ngIf="
          (stateService.userStatus$ | async) === Status.EXISTING_ACCOUNT &&
          (isTermsPage$ | async) === TermsOfUse.SHOW
        "
        class="header-right-bar">
        @if (headerService.headerElements$ | async; as headerElements) {
          <ng-container *ngIf="!(stateService.isErrorPage$ | async)">
            <a
              [href]="'mailto:' + headerService.getClientSupportMail(client)"
              *ngIf="headerElements.showServiceContactBar"
              class="service-contact">
              {{ 'header.serviceContact' | translate }}
            </a>
            <app-language-selector
              *ngIf="headerElements.showLanguageBar"
              class="language-select-dropdown" />

            <img
              *ngIf="headerElements.showSearchMenu"
              [attr.aria-hidden]="true"
              class="search"
              src="/assets/icons/searching-is.svg"
              (click)="openSearch()" />

            <ng-container *ngIf="headerElements.showBackToHome">
              <a
                (click)="goHome()"
                class="back-to-home-container small-screen-hidden">
                <span class="back-to-home">
                  @if (client === Client.ECOFASTENSOLAR) {
                    {{ 'header.returnTo' | translate }}
                    {{ 'header.ecofastenTitle' | translate }}
                  } @else {
                    {{ 'header.backTo' | translate }} {{ domainName }}
                    {{ 'header.home' | translate | lowercase }}
                  }
                </span>

                <img
                  class="back-to-home-img"
                  src="assets/icons/enstall-arrow-diagonal-lg.svg" />
              </a>
            </ng-container>

            <app-header-account-menu
              *ngIf="headerElements.showProfileMenu"
              [isHomePage]="isHomePage"></app-header-account-menu>

            <ng-container *ngIf="headerElements.showHamburgerMenu">
              <span class="hamburger">
                <img
                  class="large-screen-hidden"
                  [attr.aria-hidden]="true"
                  [src]="
                    (stateService.isMenuOpen$ | async)
                      ? 'assets/icons/close.svg'
                      : 'assets/icons/hamburger.svg'
                  "
                  (click)="toggleMenu()" />
              </span>
            </ng-container>
          </ng-container>
        }
      </div>
    </div>
  </div>
</header>
