import { BusinessUnit } from './business-unit.model';
import { SubscriptionModel } from './subscription.model';

export interface User {
  epUserUuid: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  country: string | null;
  city: string | null;
  state: string | null;
  zip?: string | null;
  postalCode: string | null;
  phone: string | null;
  mobilePhone: string | null;
  bestDescribedAs?: string | null;
  jobTitle: string | null;
  role: string | null;
  referralSource: string | null;
  intendedUses: string[] | null;
  claimedCompany: string | null;
  companyType: string | null;
  installationsPerMonth?: number | null;
  isEmailVerified: boolean | null;
  isNewAccount: boolean | null;
  isForgotPassword: boolean | null;
  isFederated: boolean | null;
  isMigrationRequired: boolean | null;
  initials: string | null;
  isActivatedAtlasAccount: boolean | null;
  isSubscribedForPromotions: boolean | null;
  termsOfUse: string | null;
  preferredLanguage: string | null;
  segment?: string | null;
  subscriptions: SubscriptionModel[];
  businessUnit: string | null; // sending it in Request
  businessUnits: BusinessUnit[] | null; // returned in Response
  userRoles?: UserRoles[];
  accountEnabled?: boolean;
}

export interface Credentials {
  email: string | null;
  password: string | null;
}

export enum Status {
  UNDEFINED = 'UNDEFINED',
  INIT = 'INIT',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  NEW_ACCOUNT = 'NEW_ACCOUNT',
  EXISTING_ACCOUNT = 'EXISTING_ACCOUNT',
}

export interface NameValue {
  name: string | null;
  value: string | number;
}

export enum UserRoles {
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN',
}
