import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { clientRegionGuard } from './core/guards/client-region.guard';
import { countryGuard } from './core/guards/country.guard';
import { googleTagManagerUserGuard } from './core/guards/google-tag-manager-user.guard';
import { navigationGuard } from './core/guards/navigation.guard';
import { tosGuard } from './core/guards/tos.guard';
import { userRegionGuard } from './core/guards/user-region.guard';
import { Region } from './core/models/region.model';
import { Status } from './core/models/user.model';
import { activeUserResolver } from './core/resolvers/active-user.resolver';
import { regionOfActiveUserAndClientResolver } from './core/resolvers/region-of-active-user-and-client.resolver';

const CORE_ROUTES: Route[] = [
  {
    path: '',
    canActivate: [
      MsalGuard,
      navigationGuard,
      tosGuard,
      googleTagManagerUserGuard,
    ],
    loadComponent: () =>
      import('./features/home/home.component').then(mod => mod.HomeComponent),
    data: {
      breadcrumb: 'Home',
      title: 'Home',
      statuses: [Status.EXISTING_ACCOUNT],
      preload: true,
    },
    resolve: {
      currentRegion: regionOfActiveUserAndClientResolver,
      user: activeUserResolver,
    },
  },
  {
    path: 'verify-email',
    canActivate: [MsalGuard, navigationGuard, googleTagManagerUserGuard],
    loadComponent: () =>
      import(
        './features/feature-branch-auth/verify-email/verify-email.component'
      ).then(mod => mod.VerifyEmailComponent),
    data: {
      breadcrumb: 'Verify email',
      title: 'Verify email',
      statuses: [Status.EMAIL_NOT_VERIFIED],
    },
  },
  {
    path: 'survey',
    canActivate: [MsalGuard, navigationGuard, googleTagManagerUserGuard],
    loadComponent: () =>
      import('./features/feature-branch-auth/survey/survey.component').then(
        mod => mod.SurveyComponent
      ),
    data: {
      breadcrumb: 'Survey',
      title: 'Create Profile',
      statuses: [Status.NEW_ACCOUNT],
    },
    resolve: {
      currentRegion: regionOfActiveUserAndClientResolver,
    },
  },
  {
    path: 'terms-conditions',
    canActivate: [MsalGuard, navigationGuard, googleTagManagerUserGuard],
    loadComponent: () =>
      import(
        './features/feature-branch-auth/terms-conditions/terms-conditions.component'
      ).then(mod => mod.TermsConditionsComponent),
    data: {
      breadcrumb: 'Terms and Service',
      title: 'Terms and Service Page',
      statuses: [Status.EXISTING_ACCOUNT],
    },
  },
  {
    path: 'search',
    canActivate: [
      MsalGuard,
      navigationGuard,
      clientRegionGuard,
      googleTagManagerUserGuard,
    ],

    loadComponent: () =>
      import(
        './features/feature-branch-intelligent-search/search-results/search-results.component'
      ).then(mod => mod.SearchResultsComponent),
    data: {
      breadcrumb: 'Search Results',
      title: 'Search Results',
      statuses: [Status.EXISTING_ACCOUNT],
      region: Region.USA, // needed for regionGuard
    },
  },
  {
    path: 'guided-tour',
    canActivate: [MsalGuard, navigationGuard, googleTagManagerUserGuard],
    loadComponent: () =>
      import('./features/guided-tour/guided-tour.component').then(
        mod => mod.GuidedTourComponent
      ),
    data: {
      breadcrumb: 'Guided Tour',
      title: 'Guided Tour',
      statuses: [Status.EXISTING_ACCOUNT],
    },
  },
  {
    path: 'manage-account',
    canActivate: [MsalGuard, navigationGuard, googleTagManagerUserGuard],
    loadComponent: () =>
      import('./features/manage-account/manage-account.component').then(
        mod => mod.ManageAccountComponent
      ),
    data: {
      breadcrumb: 'Manage Account',
      title: 'Manage Account',
      statuses: [Status.EXISTING_ACCOUNT],
    },
    resolve: {
      currentRegion: regionOfActiveUserAndClientResolver,
    },
  },
  {
    path: 'manage-account/security',
    canActivate: [MsalGuard, navigationGuard, googleTagManagerUserGuard],
    loadComponent: () =>
      import('./features/security/security.component').then(
        mod => mod.SecurityComponent
      ),
    data: {
      breadcrumb: 'Security',
      title: 'Security',
      statuses: [Status.EXISTING_ACCOUNT],
    },
  },
];

const CHAPTER_ROUTES: Route[] = [
  {
    path: 'enstall-services/chapter/subscription',
    canActivate: [
      MsalGuard,
      navigationGuard,
      countryGuard,
      googleTagManagerUserGuard,
    ],
    loadComponent: () =>
      import(
        './features/enstall-eu/services/chapter/chapter-subscription/chapter-subscription.component'
      ).then(mod => mod.ChapterSubscriptionComponent),
    data: {
      breadcrumb: 'Chapter Subscription',
      title: 'Chapter Subscription',
      statuses: [Status.EXISTING_ACCOUNT],
      allowedCountries: ['NL'],
    },
    resolve: {
      user: activeUserResolver,
    },
  },
  {
    path: 'enstall-services/chapter/subscription-success',
    canActivate: [
      MsalGuard,
      navigationGuard,
      countryGuard,
      googleTagManagerUserGuard,
    ],
    loadComponent: () =>
      import(
        './features/enstall-eu/services/chapter/chapter-subscription-success/chapter-subscription-success.component'
      ).then(mod => mod.ChapterSubscriptionSuccessComponent),
    data: {
      breadcrumb: 'Chapter Subscription Success',
      title: 'Chapter Subscription Success',
      statuses: [Status.EXISTING_ACCOUNT],
      allowedCountries: ['NL'],
    },
    resolve: {
      user: activeUserResolver,
    },
  },
  {
    path: 'enstall-services/chapter',
    canActivate: [
      MsalGuard,
      navigationGuard,
      countryGuard,
      googleTagManagerUserGuard,
    ],
    loadComponent: () =>
      import(
        './features/enstall-eu/services/chapter/enstall-service.component'
      ).then(mod => mod.EnstallHomeEUServiceComponent),
    data: {
      breadcrumb: 'Enstall Services ',
      title: 'Enstall Services',
      statuses: [Status.EXISTING_ACCOUNT],
      allowedCountries: ['NL'],
    },
    resolve: {
      user: activeUserResolver,
    },
  },
];

const ONE_FLOW_ROUTES: Route[] = [
  {
    path: 'enstall-services/one-flow/subscription',
    canActivate: [
      MsalGuard,
      navigationGuard,
      userRegionGuard,
      googleTagManagerUserGuard,
    ],
    loadComponent: () =>
      import(
        './features/enstall-eu/services/one-flow/one-flow-subscription/one-flow-subscription.component'
      ).then(mod => mod.OneFlowSubscriptionComponent),
    data: {
      breadcrumb: 'One Flow Subscription',
      title: 'One Flow Subscription',
      statuses: [Status.EXISTING_ACCOUNT],
      allowedRegion: Region.EUROPE,
    },
    resolve: {
      user: activeUserResolver,
    },
  },
  {
    path: 'enstall-services/one-flow/subscription-success',
    canActivate: [
      MsalGuard,
      navigationGuard,
      userRegionGuard,
      googleTagManagerUserGuard,
    ],
    loadComponent: () =>
      import(
        './features/enstall-eu/services/one-flow/one-flow-subscription-success/one-flow-subscription-success.component'
      ).then(mod => mod.OneFlowSubscriptionSuccessComponent),
    data: {
      breadcrumb: 'One Flow Subscription Success',
      title: 'One Flow Subscription Success',
      statuses: [Status.EXISTING_ACCOUNT],
      allowedRegion: Region.EUROPE,
    },
    resolve: {
      user: activeUserResolver,
    },
  },
  {
    path: 'enstall-services/one-flow',
    canActivate: [
      MsalGuard,
      navigationGuard,
      userRegionGuard,
      googleTagManagerUserGuard,
    ],
    loadComponent: () =>
      import(
        './features/enstall-eu/services/one-flow/one-flow-service/one-flow-service.component'
      ).then(mod => mod.OneFlowServiceComponent),
    data: {
      breadcrumb: 'Enstall Services ',
      title: 'Enstall Services',
      statuses: [Status.EXISTING_ACCOUNT],
      allowedRegion: Region.EUROPE,
    },
    resolve: {
      user: activeUserResolver,
    },
  },
];

const PRIVATE_ROUTES: Route[] = [
  ...CORE_ROUTES,
  ...CHAPTER_ROUTES,
  ...ONE_FLOW_ROUTES,
];

const PUBLIC_ROUTES: Route[] = [
  {
    path: 'email-verification',
    loadComponent: () =>
      import(
        './features/feature-branch-auth/email-verification/email-verification.component'
      ).then(mod => mod.EmailVerifiedComponent),
    data: {
      breadcrumb: 'Verified',
      title: 'Email Verification',
      statuses: [Status.INIT, Status.EMAIL_NOT_VERIFIED],
    },
  },
  {
    path: 'error-page',
    loadComponent: () =>
      import('./shared/ui/error-page/error-page.component').then(
        mod => mod.ErrorPageComponent
      ),
    data: {
      breadcrumb: 'Error Page',
      title: 'Error Page',
      statuses: [
        Status.INIT,
        Status.EMAIL_NOT_VERIFIED,
        Status.NEW_ACCOUNT,
        Status.EXISTING_ACCOUNT,
      ],
    },
  },

  {
    path: 'redirecting',
    loadComponent: () =>
      import('./shared/ui/redirect-page/redirect-page.component').then(
        mod => mod.RedirectPageComponent
      ),
    data: {
      preload: true,
    },
  },

  {
    path: '**',
    redirectTo: '/error-page',
    data: { breadcrumb: 'Page not found' },
  },
];

export const APP_ROUTES: Route[] = [...PRIVATE_ROUTES, ...PUBLIC_ROUTES];
