import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prd';
import {
  Client,
  ClientBusinessUnit,
  ClientRegion,
} from '../models/client.model';
import { Region } from '../models/region.model';
import { User } from '../models/user.model';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly document = inject(DOCUMENT);
  private readonly stateService = inject(StateService);

  readonly clientSubdomains: Record<string, Array<Client>> = {
    [environment.enstallSubdomain]: [Client.ENSTALL, Client.ENSTALL_EU],
    [environment.esdecSubdomain]: [Client.ESDEC],
    [environment.ironridgeSubdomain]: [Client.IRONRIDGE],
    [environment.ecofastensolarSubdomain]: [Client.ECOFASTENSOLAR],
  };

  /**
   * @deprecated
   */
  getClient(): Client {
    // This is regardless of environment
    const subdomains = Object.keys(this.clientSubdomains).filter(clientPrefix =>
      this.document.location.host.includes(clientPrefix)
    );

    if (subdomains.length < 1) {
      throw Error('None of the Clients are mapped to the subdomain');
    }

    // if there is just one client then return first
    if (this.clientSubdomains[subdomains[0]].length < 2) {
      return this.clientSubdomains[subdomains[0]][0];
    }

    if (this.stateService.getRegionForCurrentUser() === Region.USA) {
      return this.clientSubdomains[subdomains[0]][0];
    }

    return this.clientSubdomains[subdomains[0]][1];
  }

  getClientForUser(user: User): Client {
    // This is regardless of environment
    const subdomains = Object.keys(this.clientSubdomains).filter(clientPrefix =>
      this.document.location.host.includes(clientPrefix)
    );

    if (subdomains.length < 1) {
      throw Error('None of the Clients are mapped to the subdomain');
    }

    // if there is just one client then return first
    if (this.clientSubdomains[subdomains[0]].length < 2) {
      return this.clientSubdomains[subdomains[0]][0];
    }

    if (this.stateService.getRegionForUser(user) === Region.USA) {
      return this.clientSubdomains[subdomains[0]][0];
    }

    return this.clientSubdomains[subdomains[0]][1];
  }

  getRegionForClientAndUser(user: User): Region {
    return ClientRegion[this.getClientForUser(user)];
  }

  getBusinessUnit(user: User): string {
    const client = this.getClientForUser(user);
    return ClientBusinessUnit[client];
  }
}
