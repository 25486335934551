import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, switchMap } from 'rxjs';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';

export const countryGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const userService = inject(UserService);
  const msalBroadcastService = inject(MsalBroadcastService);

  const allowedCountriesKey = 'allowedCountries';

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    switchMap(() =>
      userService.getActiveUser().pipe(
        map((userResponse: User) => userResponse.country),
        map(userCountry => {
          const allowedCountries =
            (route.data[allowedCountriesKey] as Array<string>) ?? [];

          return allowedCountries.some(
            allowedCountry => allowedCountry === userCountry
          );
        })
      )
    )
  );
};
