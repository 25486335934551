import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { Theme } from 'src/app/app.model';
import { Client } from 'src/app/core/models/client.model';
import { ScreenSize } from 'src/app/core/models/screen.model';
import { Status, User } from 'src/app/core/models/user.model';
import { StateService } from 'src/app/core/services/state.service';

import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Region } from 'src/app/core/models/region.model';
import { GaService } from 'src/app/core/services/ga.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AuthService } from '../../../core/services/auth.service';
import { ClientService } from '../../../core/services/client.service';
import { UserService } from '../../../core/services/user.service';
import { LanguageSelectorComponent } from '../../../features/language-selector/language-selector.component';
import { FieldType } from '../../models/field-types.model';
import { TermsOfUse } from '../../models/header-elements.model';
import { HeaderService } from '../../services/header.service';
import {
  ButtonType,
  CustomButtonColor,
  CustomButtonPadding,
  CustomButtonSize,
} from '../button/button-types.model';
import { ButtonComponent } from '../button/button.component';
import { EnvironmentNotificationComponent } from '../environment-notification/environment-notification.component';
import { InputFieldComponent } from '../input-field/input-field.component';
import { SearchComponent } from '../search/search.component';
import { HeaderAccountMenuComponent } from './components/account-menu/header-account-menu.component';
import { HeaderLogoComponent } from './components/header-logo/header-logo.component';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SearchComponent,
    EnvironmentNotificationComponent,
    ButtonComponent,
    LanguageSelectorComponent,
    NgbTooltipModule,
    HeaderLogoComponent,
    HeaderAccountMenuComponent,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  TermsOfUse = TermsOfUse;
  CustomButtonColor = CustomButtonColor;
  CustomButtonPadding = CustomButtonPadding;
  CustomButtonSize = CustomButtonSize;
  ButtonType = ButtonType;

  headerService = inject(HeaderService);
  msalBroadcastService = inject(MsalBroadcastService);
  userService = inject(UserService);
  stateService = inject(StateService);
  router = inject(Router);
  authService = inject(AuthService);
  clientService = inject(ClientService);
  languageService = inject(LanguageService);
  fb = inject(FormBuilder);
  gaService = inject(GaService);
  activatedRoute = inject(ActivatedRoute);
  Client = Client;
  theme = Theme;
  form = this.fb.group({
    search: [''],
  });
  FieldType = FieldType;
  Status = Status;
  userStatus = localStorage.getItem('userStatus');
  claimedCompany: string | null = null;
  isTermsPage$ = this.stateService.user$.asObservable().pipe(
    map((user: User) => {
      return user.termsOfUse === '0' || user.termsOfUse === null
        ? TermsOfUse.HIDE
        : TermsOfUse.SHOW;
    })
  );

  url: string = '';
  domainName = '';
  screenSizes = [ScreenSize.XS, ScreenSize.S];
  isSmallScreen$ = new BehaviorSubject<boolean>(true);
  destroy$ = new Subject<void>();
  Region = Region;
  client = this.clientService.getClient();
  isHomePage: boolean = false;

  initElements$ = this.stateService.currentUrl$.pipe(
    combineLatestWith(
      this.msalBroadcastService.inProgress$.pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        switchMap(() => this.userService.getActiveUser())
      )
    ),
    tap(([currentUrl, user]) => {
      this.headerService.configureHeaderParametersByPage(currentUrl, user);
      this.isHomePage = this.headerService.isHomePage(currentUrl);
      this.client = this.clientService.getClientForUser(user);
    })
  );

  configureOnCountryChange$ = this.stateService.coutryChange$.pipe(
    switchMap(() => this.stateService.userValue$),
    tap(user =>
      this.headerService.configureHeaderParametersByPage(
        this.stateService.currentUrl$.getValue(),
        user
      )
    )
  );

  constructor() {
    this.stateService.screenSize$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((screenSize: ScreenSize) => {
        this.detectScreenSize(screenSize);
      });
    this.stateService.user$.subscribe(user => {
      this.claimedCompany = user?.claimedCompany || null;
    });
  }

  ngOnInit() {
    this.domainName = this.authService.checkDomainName();
  }

  detectScreenSize(screenSize: ScreenSize): void {
    const isSmallScreen = this.screenSizes.includes(screenSize);
    this.isSmallScreen$.next(isSmallScreen);
  }

  toggleMenu() {
    const isOpen = !this.stateService.isMenuOpen$.getValue();
    this.stateService.isMenuOpen$.next(isOpen);
  }

  goHome(): void {
    const lsUser = localStorage.getItem('user');
    const user = this.stateService.user$.getValue() ?? JSON.parse(lsUser!);

    if (user) {
      if (user.termsOfUse === '0' || user.termsOfUse === null) {
        this.router.navigate(['terms-conditions']);
      } else {
        this.userStatus = this.stateService.userStatus$.getValue();
        if (this.userStatus === Status.EXISTING_ACCOUNT) {
          this.router.navigate(['/']);
        }
      }
    }
  }

  openSearch(): void {
    const data = {
      event: 'gip_navigation',
      click_category: 'search',
      click_url: '/search',
      user_uuid: this.stateService.user$.getValue().epUserUuid,
    };

    this.gaService.sendGaData(data);

    this.router.navigate(['/search']);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
