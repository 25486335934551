import { Client } from 'src/app/core/models/client.model';

export enum PAGES {
  HOME = '',
  SERVICE = 'services',
  SURVEY = 'survey',
  MANAGE_ACCOUNT = 'manage-account',
  SEARCH = 'search',
  TERMS_CONDITIONS = 'terms-conditions',
}

export enum HEADER_ELEMENTS {
  PROFILE_DROPDOWN = 'profile-menu',
  SERVICE_CONTACT = 'service-contact',
  LANGUAGE_MENU = 'language',
  BACK_TO_HOME = 'back-home',
  HAMBURGER_MENU = 'hamburger-menu',
}

export enum LogoIconName {
  IRONRIDGE = 'ironridge-logo.svg',
  ENSTALL_IRONRIDGE = 'enstall-ironridge-logo.svg',
  ENSTALL = 'enstall-logo.svg',
  ESDEC = 'esdec-logo.svg',
  ENSTALL_ESDEC = 'enstall-esdec-logo.svg',
  ECOFASTENSOLAR = 'ecofasten-logo.svg',
  ENSTALL_ECOFASTENSOLAR = 'enstall-ecofasten-logo.svg',
}

export enum LogoIcon {
  IRONRIDGE = 'ironridge-logo',
  ENSTALL_IRONRIDGE = 'enstall-ironridge-logo',
  ENSTALL = 'enstall-logo',
  ESDEC = 'esdec-logo',
  ENSTALL_ESDEC = 'enstall-esdec-logo',
  ECOFASTENSOLAR = 'ecofasten-logo',
  ENSTALL_ECOFASTENSOLAR = 'enstall-ecofasten-logo',
}

export enum TermsOfUse {
  SHOW = 'show',
  HIDE = 'hide',
}

export const ClientLogoIconName: Record<Client | string, string> = {
  [Client.IRONRIDGE]: LogoIconName.IRONRIDGE,
  [`${Client.ENSTALL}_${Client.IRONRIDGE}`]: LogoIconName.ENSTALL_IRONRIDGE,
  [Client.ENSTALL]: LogoIconName.ENSTALL,
  [`${Client.ENSTALL}_${Client.ENSTALL}`]: LogoIconName.ENSTALL,
  [Client.ESDEC]: LogoIconName.ESDEC,
  [`${Client.ENSTALL}_${Client.ESDEC}`]: LogoIconName.ENSTALL_ESDEC,
  [Client.ENSTALL_EU]: LogoIconName.ENSTALL,
  [`${Client.ENSTALL}_${Client.ENSTALL_EU}`]: LogoIconName.ENSTALL,
  [Client.ECOFASTENSOLAR]: LogoIconName.ECOFASTENSOLAR,
  [`${Client.ENSTALL}_${Client.ECOFASTENSOLAR}`]:
    LogoIconName.ENSTALL_ECOFASTENSOLAR,
};

export const ClientLogoIconNameWithoutSuffix: Record<Client | string, string> =
  {
    [Client.IRONRIDGE]: LogoIcon.IRONRIDGE,
    [`${Client.ENSTALL}_${Client.IRONRIDGE}`]: LogoIcon.ENSTALL_IRONRIDGE,
    [Client.ENSTALL]: LogoIcon.ENSTALL,
    [`${Client.ENSTALL}_${Client.ENSTALL}`]: LogoIcon.ENSTALL,
    [Client.ESDEC]: LogoIcon.ESDEC,
    [`${Client.ENSTALL}_${Client.ESDEC}`]: LogoIcon.ENSTALL_ESDEC,
    [Client.ENSTALL_EU]: LogoIcon.ENSTALL,
    [`${Client.ENSTALL}_${Client.ENSTALL_EU}`]: LogoIcon.ENSTALL,
    [Client.ECOFASTENSOLAR]: LogoIcon.ECOFASTENSOLAR,
    [`${Client.ENSTALL}_${Client.ECOFASTENSOLAR}`]:
      LogoIcon.ENSTALL_ECOFASTENSOLAR,
  };

export type HeaderElementsModel = {
  showBackToHome: boolean;
  showSearchMenu: boolean;
  showProfileMenu: boolean;
  showLanguageBar: boolean;
  showServiceContactBar: boolean;
  showHamburgerMenu: boolean;
  currentUrl?: string;
};
