<ng-container *ngIf="externalLogInOut$ | async"></ng-container>
<ng-container *ngIf="checkAndSetActiveAccount$ | async"></ng-container>
<ng-container *ngIf="loginSuccess$ | async"></ng-container>
<ng-container *ngIf="sendGADataOnLoginSuccess$ | async"></ng-container>
<ng-container *ngIf="isRedirection$ | async">
  <ng-container *ngIf="stateService.themeSelected$ | async as themeSelected">
    <div class="spinner-wrapper">
      <div
        class="spinner-border"
        [ngClass]="{
          'text-white': themeSelected === Theme.ENSTALL
        }"
        style="z-index: 999"
        role="status"></div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!(isRedirection$ | async)">
  <div class="app-root {{ stateService.themeSelected$ | async }}">
    <!--    <ng-container *ngIf="stateService.userStatus$ | async as userStatus">-->
    <app-header></app-header>

    <div class="container-fluid">
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      @if (!isIframe) {
        <router-outlet></router-outlet>
      }
    </div>
  </div>
  <app-loader></app-loader>
</ng-container>
