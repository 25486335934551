import { interval, Observable, take, takeWhile } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const longPoll = <T>(
  source$: Observable<T>,
  stopCondition: (value: T) => boolean,
  intervalMs = 500,
  retries: number = 60,
  inclusive: boolean = true
): Observable<T> => {
  return interval(intervalMs).pipe(
    take(retries),
    switchMap(() => source$),
    takeWhile(value => stopCondition(value), inclusive)
  );
};
