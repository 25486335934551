import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, switchMap, tap } from 'rxjs';
import { GaService } from 'src/app/core/services/ga.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LanguageSelectorComponent } from 'src/app/features/language-selector/language-selector.component';
import { AuthService } from '../../../../../core/services/auth.service';
import { StateService } from '../../../../../core/services/state.service';
import { UserService } from '../../../../../core/services/user.service';
import {
  ButtonType,
  CustomButtonColor,
  CustomButtonPadding,
  CustomButtonSize,
} from '../../../button/button-types.model';
import { ButtonComponent } from '../../../button/button.component';
import { EnvironmentNotificationComponent } from '../../../environment-notification/environment-notification.component';
import { InputFieldComponent } from '../../../input-field/input-field.component';
import { SearchComponent } from '../../../search/search.component';

@Component({
  selector: 'app-header-account-menu',
  standalone: true,
  templateUrl: './header-account-menu.component.html',
  styleUrls: ['./header-account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SearchComponent,
    EnvironmentNotificationComponent,
    ButtonComponent,
    LanguageSelectorComponent,
    NgbTooltipModule,
  ],
})
export class HeaderAccountMenuComponent {
  stateService = inject(StateService);
  userService = inject(UserService);
  authService = inject(AuthService);
  languageService = inject(LanguageService);
  gaService = inject(GaService);
  router = inject(Router);

  ButtonType = ButtonType;
  CustomButtonColor = CustomButtonColor;
  CustomButtonPadding = CustomButtonPadding;
  CustomButtonSize = CustomButtonSize;

  private gaShareDataActionSubject = new Subject<void>();
  private gaShareDataAction$ = this.gaShareDataActionSubject.asObservable();

  gaShareData$ = this.gaShareDataAction$.pipe(
    switchMap(() =>
      this.userService.getActiveUser().pipe(
        tap(user => this.sendDataManageAccount(user.epUserUuid)),
        tap(() => this.router.navigate(['/manage-account']))
      )
    )
  );

  @Input() isHomePage: boolean = false;

  openLink(type: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    let url = '';
    if (type === 'privacy') {
      url = 'https://enstall.com/privacy-policy/';
    } else if (type === 'terms') {
      url =
        'https://enstall.com/wp-content/uploads/2024/03/Enstall-Digital-Services-Platform-Terms-of-Use.pdf';
    }

    if (url?.length) {
      window.open(url, '_blank');
    }
  }

  logout() {
    this.authService.silentLogout();
  }

  shareDataManageAccount(): void {
    this.gaShareDataActionSubject.next();
  }

  private sendDataManageAccount(userUuid: string | null): void {
    const data = {
      event: 'manage_account',
      user_uuid: userUuid,
      language: this.languageService.getCurrentLanguageCode(),
    };

    this.gaService.sendGaData(data);
  }
}
