import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { concatMap, filter } from 'rxjs';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';

export const activeUserResolver: ResolveFn<User> = () => {
  const userService = inject(UserService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    concatMap(() => userService.getActiveUser())
  );
};
