import { Region } from './region.model';

export enum Client {
  ENSTALL_EU = 'ENSTALL_EU',
  IRONRIDGE = 'IRONRIDGE',
  ENSTALL = 'ENSTALL',
  ESDEC = 'ESDEC',
  ECOFASTENSOLAR = 'ECOFASTENSOLAR',
  ERROR = 'ERROR',
}

export const ClientRegion: Record<Client, Region> = {
  [Client.IRONRIDGE]: Region.USA,
  [Client.ENSTALL]: Region.USA,
  [Client.ECOFASTENSOLAR]: Region.USA,
  [Client.ESDEC]: Region.EUROPE,
  [Client.ENSTALL_EU]: Region.EUROPE,
  [Client.ERROR]: Region.USA,
};

export const ClientBusinessUnit: Record<Client, string> = {
  [Client.IRONRIDGE]: 'ironridge',
  [Client.ENSTALL]: 'enstall',
  [Client.ECOFASTENSOLAR]: 'ecofasten',
  [Client.ESDEC]: 'esdec',
  [Client.ENSTALL_EU]: 'enstall',
  [Client.ERROR]: 'error',
};
