import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, switchMap } from 'rxjs';
import { Region } from '../models/region.model';
import { ClientService } from '../services/client.service';
import { UserService } from '../services/user.service';

export const regionOfActiveUserAndClientResolver: ResolveFn<Region> = () => {
  const userService = inject(UserService);
  const clientService = inject(ClientService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    switchMap(() =>
      userService
        .getActiveUser()
        .pipe(map(user => clientService.getRegionForClientAndUser(user)))
    )
  );
};
