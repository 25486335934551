import { Inject, inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  ClearCacheRequest,
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { EndSessionRequest } from '@azure/msal-browser/src/request/EndSessionRequest';
import jwt_decode from 'jwt-decode';
import {
  catchError,
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { environment } from '../../../environments/environment.prd';
import { EnvironmentName } from '../../../environments/util/environment.name';
import { Theme } from '../../app.model';
import { TokenType } from '../models/auth.model';
import { Client } from '../models/client.model';
import { Language } from '../models/supported-language.model';
import { Status, User } from '../models/user.model';
import { isAnyEnvironmentOf } from '../utils/environment.util';
import { getUrlPath } from '../utils/route.util';
import { ClientService } from './client.service';
import { LocalStorageService } from './local-storage.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  host!: string;
  apiUrl = environment.apiUrl;
  client!: Client;
  domainName!: string;
  theme!: Theme;
  clientService = inject(ClientService);
  msalService = inject(MsalService);
  msalBroadcastService = inject(MsalBroadcastService);
  stateService = inject(StateService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  localStorageService = inject(LocalStorageService);

  private readonly ESDEC_REDIRECT_URL = 'http://esdec.com';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.host = this.getHost();
  }

  getHost(): string {
    return document.location.protocol + '//' + document.location.host;
  }

  getHostAndPath(): string {
    return this.getHost() + getUrlPath(this.route.snapshot);
  }

  checkDomainName(): string {
    switch (this.clientService.getClient()) {
      case Client.ENSTALL:
        this.domainName = 'Enstall';
        this.client = Client.ENSTALL;
        this.theme = Theme.ENSTALL;
        break;
      case Client.ENSTALL_EU:
        this.domainName = 'Enstall';
        this.client = Client.ENSTALL_EU;
        this.theme = Theme.ENSTALL_EU;
        break;
      case Client.IRONRIDGE:
        this.domainName = 'IronRidge';
        this.client = Client.IRONRIDGE;
        this.theme = Theme.IRONRIDGE;
        break;
      case Client.ESDEC:
        this.domainName = 'Esdec';
        this.client = Client.ESDEC;
        this.theme = Theme.ESDEC;
        break;
      case Client.ECOFASTENSOLAR:
        this.domainName = 'Ecofasten';
        this.client = Client.ECOFASTENSOLAR;
        this.theme = Theme.ECOFASTENSOLAR;
        break;
      default:
        this.domainName = 'ERROR';
        this.client = Client.ERROR;
    }
    this.stateService.setDomainName(this.domainName);
    this.stateService.setClient(this.client);
    this.stateService.setTheme(this.theme);

    return this.domainName;
  }

  getDomainName(): string {
    return this.domainName;
  }

  private setActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  acquireToken(tokenType: TokenType = TokenType.ID_TOKEN): Observable<string> {
    this.setActiveAccount();
    const account = this.msalService.instance.getAllAccounts()[0];

    const scopes =
      tokenType === TokenType.ACCESS_TOKEN
        ? environment.msalConfig.auth.scopes
        : [environment.msalConfig.auth.clientId];
    const silentRequest = {
      scopes,
      account,
    } as SilentRequest;

    const urlParams = new URLSearchParams(window.location.search);
    const uiLocales = urlParams?.get('ui_locales') || 'en';

    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap(() =>
        this.msalService.acquireTokenSilent(silentRequest).pipe(
          map(result => result[tokenType] as string),
          catchError(() => {
            this.msalService.instance.acquireTokenRedirect({
              authority:
                environment.msalConfig.auth.baseAuthorityUrl +
                environment.msalConfig.auth.authority,
              scopes: environment.msalConfig.auth.scopes,
              extraQueryParameters: {
                ui_locales: uiLocales,
              },
            });
            return EMPTY;
          })
        )
      )
    );
  }

  getUserUuid(): Observable<string | null | undefined> {
    return this.acquireToken().pipe(
      map(token => jwt_decode(token) as Partial<User>),
      map(user => user?.epUserUuid)
    );
  }

  getUserFromSession(): Observable<User> {
    return this.acquireToken().pipe(map(token => jwt_decode(token) as User));
  }

  loginRedirect(usersLanguage?: Language) {
    let redirectRequest;
    if (usersLanguage) {
      redirectRequest = {
        extraQueryParameters: { ui_locales: usersLanguage },
      } as Partial<RedirectRequest>;
    }
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...redirectRequest,
        ...this.msalGuardConfig.authRequest,
        prompt: environment.prompt,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  silentLogout() {
    this.getUserFromSession()
      .pipe(
        map(user => Boolean(user.isFederated)),
        tap(isFederated => {
          if (isFederated) {
            const redirectUrl = this.setPostLogoutRedirectUri(
              isFederated
            ) as string;
            const url = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectUrl}`;
            window.open(url, '_blank');
          }

          const logoutParams = this.setPostLogoutRedirectUri(
            Boolean(isFederated)
          ) as EndSessionRequest | undefined;
          this.clearLocalStorage();
          this.clearState();
          this.localStorageService.clearAll();
          this.msalService.instance.logoutRedirect(logoutParams);
        }),
        take(1)
      )
      .subscribe();
  }

  setUser(user: User) {
    user.isActivatedAtlasAccount = user.isActivatedAtlasAccount || false;
    user.initials = `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(
      0
    )}`.toUpperCase();

    this.stateService.user$.next(user);
    this.localStorageService.setItem('user', JSON.stringify(user));
    this.setUserStatusFromUser(user);
    this.checkDomainName();
  }

  getUserStatusFromUser(user: User): Status {
    if (user.isEmailVerified && !user.isNewAccount) {
      return Status.EXISTING_ACCOUNT;
    } else if (user.isEmailVerified && user.isNewAccount) {
      return Status.NEW_ACCOUNT;
    } else if (!user.isEmailVerified) {
      return Status.EMAIL_NOT_VERIFIED;
    } else {
      return Status.INIT;
    }
  }

  clearLocalStorage() {
    this.localStorageService.clearAll(
      'user',
      'userStatus',
      'domainName',
      'theme',
      'client'
    );
  }

  clearState() {
    this.stateService.isLoggedIn$.next(false);
    this.stateService.userStatus$.next(Status.INIT);
    this.stateService.user$.next({} as User);
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  signUpRedirect() {
    const redirectUrl =
      this.domainName === 'ironridge'
        ? this.host + '/verify-email' + '&affiliate=ir_da'
        : this.host + '/verify-email';
    const loginRequest = {
      authority:
        environment.msalConfig.auth.baseAuthorityUrl +
        environment.msalConfig.auth.signUpAuthority,
      redirectUri: redirectUrl,
      scopes: environment.msalConfig.auth.scopes,
      prompt: environment.prompt,
    };
    this.msalService.loginRedirect(loginRequest);
  }

  navigateUser(user: User, currentUrl: string) {
    const status = this.getUserStatusFromUser(user);
    if (status === Status.EMAIL_NOT_VERIFIED) {
      if (this.router.url.includes('/email-verification')) {
        this.router.navigate([this.router.url]);

        return;
      }
      this.router.navigate(['verify-email']);
      return;
    }

    if (status === Status.NEW_ACCOUNT) {
      this.router.navigate(['survey']);

      return;
    }

    if (
      ['/survey', '/verify-email', '/redirecting'].some(
        url => url === currentUrl
      )
    ) {
      if (user.termsOfUse === '0' || !user.termsOfUse) {
        this.router.navigate(['/terms-conditions']);
      } else {
        this.router.navigate(['/']);
      }

      return;
    }
  }

  private setUserStatus(status: Status) {
    this.stateService.userStatus$.next(status);
    localStorage.setItem('userStatus', status);
  }

  private setUserStatusFromUser(user: User): Status {
    if (user.isEmailVerified && !user.isNewAccount) {
      this.setUserStatus(Status.EXISTING_ACCOUNT);
      return Status.EXISTING_ACCOUNT;
    } else if (user.isEmailVerified && user.isNewAccount) {
      this.setUserStatus(Status.NEW_ACCOUNT);
      return Status.NEW_ACCOUNT;
    } else if (!user.isEmailVerified) {
      this.setUserStatus(Status.EMAIL_NOT_VERIFIED);
      return Status.EMAIL_NOT_VERIFIED;
    } else {
      this.setUserStatus(Status.INIT);
      return Status.INIT;
    }
  }

  private setPostLogoutRedirectUri(
    isFederatedUser: boolean
  ): string | EndSessionRequest | undefined {
    // Federated user
    if (isFederatedUser) {
      return this.client === Client.ESDEC &&
        isAnyEnvironmentOf([EnvironmentName.stg, EnvironmentName.prd])
        ? this.ESDEC_REDIRECT_URL
        : document.location.protocol + '//' + document.location.host;
    }
    // Regular user
    const activeAccount = this.msalService.instance.getActiveAccount();
    const redirectRequest: EndSessionRequest = {
      account: activeAccount,
    };
    this.msalService.instance.clearCache({
      account: activeAccount,
    } as ClearCacheRequest);
    return this.client === Client.ESDEC &&
      isAnyEnvironmentOf([EnvironmentName.stg, EnvironmentName.prd])
      ? { ...redirectRequest, postLogoutRedirectUri: this.ESDEC_REDIRECT_URL }
      : redirectRequest;
  }
}
