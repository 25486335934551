import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, switchMap } from 'rxjs';
import { Region } from '../models/region.model';
import { StateService } from '../services/state.service';
import { UserService } from '../services/user.service';

export const userRegionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const userService = inject(UserService);
  const stateService = inject(StateService);
  const router = inject(Router);
  const msalBroadcastService = inject(MsalBroadcastService);

  const allowedRegion = 'allowedRegion';

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    switchMap(() =>
      userService.getActiveUser().pipe(
        switchMap(() => {
          const region = (route.data[allowedRegion] as Region) ?? Region.EUROPE;

          return stateService.isUserFromRegion(region).pipe(
            map(isFromRegion => {
              if (!isFromRegion) {
                router.navigate(['/']);
              }
              return isFromRegion;
            })
          );
        })
      )
    )
  );
};
