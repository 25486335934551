@if (gaShareData$ | async) {}
<!-- Account  menu -->
<div
  class="dropdown"
  [ngClass]="{ 'display-none ': !isHomePage }"
  id="profileMenu"
  data-bs-toggle="dropdown"
  aria-expanded="false">
  <div class="initials">
    <div class="circle bg-gray">
      {{ (stateService.user$ | async)?.initials }}
    </div>

    <img src="assets/icons/down-arrow.svg" />
  </div>

  <ul class="dropdown-menu" aria-labelledby="profileMenu">
    <!-- Account details -->
    <div class="account-details">
      <h4 class="account-name">
        {{ 'header.userHeadline' | translate }}
      </h4>
      <h3 class="account-full-name">
        {{ (stateService.user$ | async)?.firstName }}
        {{ (stateService.user$ | async)?.lastName }}
      </h3>
      <p
        class="user-email"
        placement="bottom"
        ngbTooltip="{{ (stateService.user$ | async)?.email }}">
        {{ (stateService.user$ | async)?.email }}
      </p>
      <app-button
        [name]="'manageAccountButton'"
        [bgColorClass]="CustomButtonColor.WHITE_COLOR"
        [paddingClass]="CustomButtonPadding.SMALL_PADDING"
        [sizeClass]="CustomButtonSize.FIT_SIZE"
        [type]="ButtonType.BUTTON"
        [buttonText]="'header.manageAccount' | translate"
        (buttonClicked)="shareDataManageAccount()"></app-button>
    </div>

    <!-- Company workspace details -->
    <div *ngIf="(stateService.user$ | async)?.claimedCompany as claimedCompany">
      <hr />

      <div class="user-company-details">
        <h4 class="company-name">
          {{ 'header.companyHeadline' | translate }}
        </h4>
        <h3 class="details">{{ claimedCompany }}</h3>
      </div>
    </div>

    <hr />

    <!-- Add-ones for demo -->
    <div [attr.aria-hidden]="true" class="logout" (click)="logout()">
      <img src="assets/icons/sign-out.svg" />
      <div class="logout-label">
        {{ 'header.logout' | translate }}
      </div>
    </div>

    <hr />

    <!-- Terms and Conditions -->
    <div [attr.aria-hidden]="true" class="privacy-terms">
      <a (click)="openLink('privacy', $event)">{{
        'header.privacyPolicy' | translate
      }}</a>
      <div class="vertical-divider"></div>
      <a (click)="openLink('terms', $event)">{{
        'header.termsService' | translate
      }}</a>
    </div>
  </ul>
</div>
