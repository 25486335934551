import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectivePreloadingStrategy implements PreloadingStrategy {
  preload(
    route: Route,
    load: () => Observable<object>
  ): Observable<object | null> {
    return route?.data?.preload ? load() : of(null);
  }
}
