import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem(key: string, item: string): void {
    localStorage.setItem(key, item);
  }

  getItem<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key)!) as T;
  }

  clearAll(...keys: Array<string>): void {
    if (keys.length > 0) {
      keys.forEach(key => localStorage.removeItem(key));
    } else {
      localStorage.clear();
    }
  }
}
