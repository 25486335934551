import { CodeDisplay } from '../../shared/models/code-display.model';

export enum Language {
  en = 'en',
  de = 'de',
  es = 'es',
  fr = 'fr',
  nl = 'nl',
  pl = 'pl',
  pt = 'pt',
  sv = 'sv',
}

export const DEFAULT_LANGUAGE = Language.en;

export const systemLanguage: Record<Language, string> = {
  [Language.en]: 'en-US',
  [Language.de]: 'de-DE',
  [Language.es]: 'es-ES',
  [Language.fr]: 'fr-FR',
  [Language.nl]: 'nl-NL',
  [Language.pl]: 'pl-PL',
  [Language.pt]: 'pt-PT',
  [Language.sv]: 'sv-SE',
};

export const systemLanguageDOM: Record<Language, string> = {
  [Language.en]: 'en',
  [Language.de]: 'de_DE',
  [Language.es]: 'es_ES',
  [Language.fr]: 'fr_FR',
  [Language.nl]: 'nl_NL',
  [Language.pl]: 'pl_PL',
  [Language.pt]: 'pt_PT',
  [Language.sv]: 'sv_SV',
};

export const dropdownLanguages: Array<CodeDisplay> = [
  {
    code: systemLanguage[Language.en],
    display: 'EN',
  },
  {
    code: systemLanguage[Language.de],
    display: 'DE',
  },
  {
    code: systemLanguage[Language.es],
    display: 'ES',
  },
  {
    code: systemLanguage[Language.fr],
    display: 'FR',
  },
  {
    code: systemLanguage[Language.nl],
    display: 'NL',
  },
  {
    code: systemLanguage[Language.pl],
    display: 'PL',
  },
  {
    code: systemLanguage[Language.pt],
    display: 'PT',
  },
  {
    code: systemLanguage[Language.sv],
    display: 'SV',
  },
];
