/// <reference types="@angular/localize" />
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import {
  BrowserModule,
  REMOVE_STYLES_ON_COMPONENT_DESTROY,
  bootstrapApplication,
} from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  Router,
  provideRouter,
  withDisabledInitialNavigation,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserUtils,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideToastr } from 'ngx-toastr';

import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { authInterceptorFactory } from './app/core/interceptors/auth-interceptor.factory';
import { AuthInterceptor } from './app/core/interceptors/auth.interceptor';
import { SelectivePreloadingStrategy } from './app/core/strategies/selective-preloading.strategy';
import { environment } from './environments/environment.prd';

import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: 'https://cd847d7eba9ff4a72819f5f7794841a8@o4507736795185152.ingest.de.sentry.io/4507737347719248',
  environment: environment.name,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environment.apiUrl],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?cb=' + new Date().getTime()
  );
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority:
        environment.msalConfig.auth.baseAuthorityUrl +
        environment.msalConfig.auth.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      knownAuthorities: environment.msalConfig.auth.knownAuthorities,
    },
    cache: {
      cacheLocation: environment.msalConfig.cache.cacheLocation,
      storeAuthStateInCookie:
        environment.msalConfig.cache.storeAuthStateInCookie,
    },
    system: {
      allowNativeBroker: false,
    },
  });

  return msalInstance;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    environment.msalConfig.auth.uri,
    environment.msalConfig.auth.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.msalConfig.auth.scopes],
    },
    loginFailedRoute: '/redirecting',
  };
}

const initialNavigation =
  !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? withEnabledBlockingInitialNavigation()
    : withDisabledInitialNavigation();

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en-US',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideAnimations(),

    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: false },

    provideToastr({
      timeOut: 3000,
      easing: 'ease-in',
      easeTime: 500,
      positionClass: 'toast-bottom-full-width',
      progressBar: false,
      preventDuplicates: true,
      progressAnimation: 'increasing',
      closeButton: false,
      tapToDismiss: false,
      disableTimeOut: false,
      newestOnTop: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: authInterceptorFactory,
      multi: true,
    },

    AuthInterceptor,

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideRouter(
      APP_ROUTES,
      initialNavigation,
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
      withPreloading(SelectivePreloadingStrategy)
    ),
  ],
}).catch(err => console.error(err));
