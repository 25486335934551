<button
  [type]="type"
  [class]="'btn ' + ' ' + bgColorClass + ' ' + paddingClass + ' ' + sizeClass"
  [id]="name"
  [name]="name"
  (click)="onButtonClick()"
  [disabled]="disabled"
  [attr.tabindex]="tabIndex">
  <ng-container *ngIf="iconBefore">
    <img class="icon-margin-right" [src]="iconBefore" alt="Icon before text" />
  </ng-container>

  {{ buttonText }}

  <ng-container *ngIf="iconAfter">
    <img class="icon-margin-left" [src]="iconAfter" alt="Icon after text" />
  </ng-container>
</button>
