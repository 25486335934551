import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from 'src/app/shared/ui/loader/loader.service';
import { ErrorType } from '../models/error.model';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private toaster: ToasterService,
    private loader: LoaderService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any, type = ErrorType.ERROR): Observable<any> {
    const message = error.message;
    const hasNoAccount = error.message.includes('No account');
    if (!hasNoAccount) {
      type === ErrorType.ERROR
        ? this.toaster.showError(message)
        : this.toaster.showInfo(message);
      this.loader.isLoading$.next(false);
    }
    return throwError(() => error);
  }
}
