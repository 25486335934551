import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Region } from 'src/app/core/models/region.model';
import { ClientService } from 'src/app/core/services/client.service';
import { Client, ClientBusinessUnit } from '../../core/models/client.model';
import { User } from '../../core/models/user.model';
import {
  HEADER_ELEMENTS,
  HeaderElementsModel,
  PAGES,
} from '../models/header-elements.model';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  clientService = inject(ClientService);
  headerElementsOrder: Array<HEADER_ELEMENTS> = [];
  serviceContactBarMail: Record<Client, string> = {
    [Client.ESDEC]: 'salessupport@esdec.com',
    [Client.ECOFASTENSOLAR]: 'info@ecofastensolar.com',
    [Client.ENSTALL_EU]: '',
    [Client.IRONRIDGE]: '',
    [Client.ENSTALL]: '',
    [Client.ERROR]: '',
  };

  private headerElementsSubject = new BehaviorSubject<HeaderElementsModel>(
    {} as HeaderElementsModel
  );
  headerElements$ = this.headerElementsSubject.asObservable();
  headerLogoName = signal('');

  constructor() {}

  configureHeaderParametersByPage(path: string, user: User) {
    this.setHeaderElementOrder(path);
    this.initParameters(path, user);
  }

  private setHeaderElementOrder(path: string) {
    if (this.isServicePage(path) || this.isManageAccountPage(path)) {
      this.headerElementsOrder = [
        HEADER_ELEMENTS.LANGUAGE_MENU,
        HEADER_ELEMENTS.SERVICE_CONTACT,
        HEADER_ELEMENTS.BACK_TO_HOME,
      ];
    } else {
      this.headerElementsOrder = [
        HEADER_ELEMENTS.LANGUAGE_MENU,
        HEADER_ELEMENTS.PROFILE_DROPDOWN,
        HEADER_ELEMENTS.SERVICE_CONTACT,
      ];
    }
  }

  isHomePage(url: string) {
    return url.search(PAGES.HOME) > -1;
  }

  isServicePage(url: string) {
    return url.search(PAGES.SERVICE) > -1;
  }

  isManageAccountPage(url: string): boolean {
    return url.search(PAGES.MANAGE_ACCOUNT) > -1;
  }

  isSurveyPage(url: string): boolean {
    return url.search(PAGES.SURVEY) > -1;
  }

  initParameters(path: string, user: User) {
    const showLanguageBar =
      this.isElementShownOnPage(HEADER_ELEMENTS.LANGUAGE_MENU) &&
      this.clientService.getRegionForClientAndUser(user) == Region.EUROPE;

    const showBackToHome = this.isElementShownOnPage(
      HEADER_ELEMENTS.BACK_TO_HOME
    );

    const showProfileMenu = this.isElementShownOnPage(
      HEADER_ELEMENTS.PROFILE_DROPDOWN
    );

    const showServiceContactBar =
      this.isElementShownOnPage(HEADER_ELEMENTS.SERVICE_CONTACT) &&
      (this.clientService.getRegionForClientAndUser(user) == Region.EUROPE ||
        ClientBusinessUnit[Client.ECOFASTENSOLAR] ===
          this.clientService.getBusinessUnit(user));

    const showSearchMenu =
      this.clientService.getRegionForClientAndUser(user) === Region.USA &&
      this.clientService.getBusinessUnit(user) !==
        ClientBusinessUnit[Client.ECOFASTENSOLAR];

    const showHamburgerMenu =
      this.clientService.getRegionForClientAndUser(user) === Region.EUROPE;

    const result = {
      showLanguageBar,
      showBackToHome,
      showProfileMenu,
      showServiceContactBar,
      showSearchMenu,
      showHamburgerMenu,
      currentUrl: path,
    } as HeaderElementsModel;

    this.headerElementsSubject.next(result);

    return result;
  }

  isElementShownOnPage(element: HEADER_ELEMENTS) {
    return this.headerElementsOrder.includes(element);
  }

  getClientSupportMail(client: Client) {
    return this.serviceContactBarMail[client];
  }
}
