<div
  *ngIf="!environment.production"
  class="environment-{{ environment.name }} pointer"
  data-bs-toggle="dropdown"
  data-bs-auto-close="true"
  aria-expanded="false">
  {{ 'header.thisIs' | translate }} <b>{{ environment.name }}</b>
  {{ 'header.environment' | translate }}.
</div>
<div class="dropdown">
  <ul
    class="dropdown-menu bg-danger text-white"
    aria-labelledby="dropdownMenu2">
    <li>{{ stateService.userStatus$ | async }}</li>
    <li>
      <button
        type="button"
        class="dropdown-item pointer"
        (click)="authService.silentLogout()">
        {{ 'header.logout' | translate }}
      </button>
    </li>

    <li>
      <button
        class="dropdown-item pointer"
        type="button"
        (click)="authService.signUpRedirect()">
        {{ 'header.createAccount' | translate }}
      </button>
    </li>

    <li>
      <button
        class="dropdown-item pointer"
        type="button"
        (click)="goToMaintenancePage()">
        {{ 'header.maintence' | translate }}
      </button>
    </li>

    <li>
      <button
        class="dropdown-item pointer"
        type="button"
        (click)="goToTermsConditions()">
        {{ 'header.terms' | translate }}
      </button>
    </li>
  </ul>
</div>
