import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(
    private translate: TranslateService,
    private toaster: ToastrService
  ) {}

  showSuccess(message: string, title?: string) {
    if (title) {
      this.toaster.success(
        this.translate.instant(message),
        this.translate.instant(title)
      );
    } else {
      this.toaster.success(this.translate.instant(message));
    }
  }

  showError(message: string, title?: string) {
    if (title) {
      this.toaster.error(
        this.translate.instant(message),
        this.translate.instant(title)
      );
    } else {
      this.toaster.error(this.translate.instant(message));
    }
  }

  showInfo(message: string, title?: string) {
    if (title) {
      this.toaster.info(
        this.translate.instant(message),
        this.translate.instant(title)
      );
    } else {
      this.toaster.info(this.translate.instant(message));
    }
  }

  showWarning(message: string, title?: string) {
    if (title) {
      this.toaster.warning(
        this.translate.instant(message),
        this.translate.instant(title)
      );
    } else {
      this.toaster.warning(this.translate.instant(message));
    }
  }
}
