import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Client } from 'src/app/core/models/client.model';
import { StateService } from 'src/app/core/services/state.service';
import { environment } from 'src/environments/environment.prd';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-environment-notification',
  standalone: true,
  templateUrl: './environment-notification.component.html',
  styleUrls: ['./environment-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule],
})
export class EnvironmentNotificationComponent {
  protected readonly environment = environment;
  stateService = inject(StateService);
  authService = inject(AuthService);
  router = inject(Router);
  Client = Client;

  goToMaintenancePage() {
    window.open(
      'http://my.enstall.com.s3-website-us-east-1.amazonaws.com/',
      '_blank'
    );
  }

  goToTermsConditions() {
    this.router.navigate(['/terms-conditions']);
  }
}
