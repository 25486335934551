import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActivationEnd,
  EventType,
  NavigationEnd,
  NavigationSkipped,
  Router,
  RouterModule,
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, of, switchMap } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { Status } from 'src/app/core/models/user.model';
import { StateService } from 'src/app/core/services/state.service';
import { getUrlPath } from '../../../../../core/utils/route.util';
import { ClientLogoIconNameWithoutSuffix } from '../../../../models/header-elements.model';
import { HeaderService } from '../../../../services/header.service';

@Component({
  selector: 'app-header-logo',
  standalone: true,
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class HeaderLogoComponent {
  stateService = inject(StateService);
  headerService = inject(HeaderService);
  router = inject(Router);

  @Input() client: Client = Client.ENSTALL;
  @Input() isTermsPage: string | null = 'hide';

  @Output() goToHome = new EventEmitter();

  Status = Status;
  Client = Client;

  logoSrcName$ = this.router.events.pipe(
    filter(event =>
      [
        EventType.NavigationEnd,
        EventType.NavigationSkipped,
        EventType.ActivationEnd,
      ].some(eventType => eventType === event.type)
    ),
    map(event => event as NavigationEnd | NavigationSkipped | ActivationEnd),
    switchMap((event: NavigationEnd | NavigationSkipped | ActivationEnd) => {
      if (this.isCobrandedPage(event)) {
        const logoName =
          ClientLogoIconNameWithoutSuffix[`${Client.ENSTALL}_${this.client}`];
        this.headerService.headerLogoName.set(logoName);

        return of(logoName);
      }

      const logoName = ClientLogoIconNameWithoutSuffix[this.client];
      this.headerService.headerLogoName.set(logoName);

      return of(logoName);
    })
  );

  goHome(): void {
    this.goToHome.emit();
  }

  private isCobrandedPage(
    event: NavigationEnd | NavigationSkipped | ActivationEnd
  ): boolean {
    switch (event.type) {
      case EventType.NavigationEnd:
      case EventType.NavigationSkipped:
        return (
          this.headerService.isSurveyPage(event.url) ||
          this.headerService.isManageAccountPage(event.url)
        );
      case EventType.ActivationEnd:
        // eslint-disable-next-line no-case-declarations
        const url = getUrlPath(event.snapshot);
        return (
          this.headerService.isSurveyPage(url) ||
          this.headerService.isManageAccountPage(url)
        );
      default:
        return false;
    }
  }
}
