export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

export enum CustomButtonColor {
  PRIMARY_COLOR = 'btn-primary',
  SECONDARY_COLOR = 'btn-secondary',
  GHOST_COLOR = 'btn-ghost',
  GREEN_COLOR = 'general-green-button',
  WHITE_COLOR = 'general-white-button',
}

export enum CustomButtonPadding {
  SMALL_PADDING = 'small-btn-padding',
  MEDIUM_PADDING = 'medium-btn-padding',
  LARGE_PADDING = 'large-btn-padding',
}

export enum CustomButtonSize {
  FIT_SIZE = 'fit-content-button',
  MAX_SIZE = 'max-width-button',
  MIXED_SIZE = 'mixed-width-button',
}
