import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { concatMap, filter, map } from 'rxjs';
import { GaService } from '../services/ga.service';
import { UserService } from '../services/user.service';

export const tosGuard: CanActivateFn = () => {
  sendGaEvent();
  const router = inject(Router);
  const userService = inject(UserService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    concatMap(() =>
      userService.getActiveUser().pipe(
        map(user => user.termsOfUse),
        map(termsOfUse => {
          if (termsOfUse === '0' || termsOfUse === null) {
            router.navigate(['/terms-conditions']);
            return false;
          }
          return true;
        })
      )
    )
  );
};

function sendGaEvent() {
  const gaService = inject(GaService);
  const data = {
    event: 'page_guard',
  };
  gaService.sendGaData(data);
}
