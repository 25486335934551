<ng-container *ngIf="logoSrcName$ | async"></ng-container>
<div class="logo">
  <div
    [ngClass]="{
      pointer: (stateService.userStatus$ | async) === Status.EXISTING_ACCOUNT
    }"
    [id]="client + 'Logo'"
    [class]="headerService.headerLogoName()"
    (click)="isTermsPage === 'show' && goHome()"></div>
</div>
